// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};
// const state:any = useSelector(state=>state)

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general v3.0.0',
    items: [
      {
        title: 'Online Admission ',
        path: '/dashboard/admission',
        icon: ICONS.dashboard,
        children: [
          {
            title: 'Translations',
            path: '/dashboard/admission/translation',
            icon: ICONS.dashboard,
          },
          {
            title: 'Translations List',
            path: '/dashboard/admission/translation_list',
            icon: ICONS.ecommerce,
          },
        ],
      },
      // School
      {
        title: 'Online School',
        path: '/dashboard/school',
        icon: ICONS.dashboard,
        children: [
          { title: 'Translations', path: '/dashboard/school/translation', icon: ICONS.dashboard },
          {
            title: 'Translations List',
            path: '/dashboard/school/translation_list',
            icon: ICONS.ecommerce,
          },
        ],
      },
      //account
      {
        title: 'Online Account',
        path: '/dashboard/account',
        icon: ICONS.dashboard,
        children: [
          { title: 'Translations', path: '/dashboard/account/translation', icon: ICONS.dashboard },
          {
            title: 'Translations List',
            path: '/dashboard/account/translation_list',
            icon: ICONS.ecommerce,
          },
        ],
      },
      //hrms
      {
        title: 'Online HRMS',
        path: '/dashboard/hrms',
        icon: ICONS.dashboard,
        children: [
          { title: 'Translations', path: '/dashboard/hrms/translation', icon: ICONS.dashboard },
          {
            title: 'Translations List',
            path: '/dashboard/hrms/translation_list',
            icon: ICONS.ecommerce,
          },
        ],
      },

      //timetable
      {
        title: 'Online Timetable',
        path: '/dashboard/timetable',
        icon: ICONS.dashboard,
        children: [
          { title: 'Translations', path: '/dashboard/timetable/translation', icon: ICONS.dashboard },
          {
            title: 'Translations List',
            path: '/dashboard/timetable/translation_list',
            icon: ICONS.ecommerce,
          },
        ],
      },
      // Transportation
      {
        title: 'Transportation',
        path: '/dashboard/transportation',
        icon: ICONS.dashboard,
        children: [
          { title: 'Translations', path: '/dashboard/transportation/translation', icon: ICONS.dashboard },
          {
            title: 'Translations List',
            path: '/dashboard/transportation/translation_list',
            icon: ICONS.ecommerce,
          },
        ],
      },
      //Wysax website
      {
        title: 'Wysax Website',
        path: '/dashboard/wysax-web',
        icon: ICONS.dashboard,
        children: [
          { title: 'Translations', path: '/dashboard/wysax/translation', icon: ICONS.dashboard },
          {
            title: 'Translations List',
            path: '/dashboard/wysax/translation_list',
            icon: ICONS.ecommerce,
          },
        ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
