import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  postOnlineAdmissionTranslationAuth,
  checkTokenExpire,
} from 'src/online translation/Services/online-admission.service';
import store from '../store';
import { doneLoginData, loadLoginFail, sessionExpired } from '../action';
//
// this function is used to check the type of user allow the action to the user
const checkRoleAllow=(role:string)=>{
  
  const userDetails: any = store.getState().userDetails;  
  let allow = {...userDetails.allowActions, delete: false,edit:false,download:false,check_translate:false };
  if(role === "TRANSLATER"){
    allow ={...allow, edit:true }
  }
  if(role === "SUPER_ADMIN"){
    allow ={...allow, delete: true,edit:true,download:true,check_translate:true }
  }
  if(role === "DEVELOPER"){
    allow ={...allow, download:true }
  }
  if(role==="TESTER"){
    allow ={...allow,check_translate:true }
  }
  if(role==="THAI_ADMIN"){
    allow ={...allow,delete: true,edit:true,download:true,check_translate:true }
  }
  return allow
} 
// 
function* fetchLoginData(props: any): any {
  // rename props to action
  const query = props.payload;
  try {
    const user_data: any = yield call(userLogin, query);
    let allow = checkRoleAllow(user_data.role)
    yield put(doneLoginData({user_data,allow}));
  } catch (e) {
    yield put(loadLoginFail(e));
  }
}
//
const userLogin = async (user: any) => {
  const res = await postOnlineAdmissionTranslationAuth(user);
  return res.data;
};

function* fetchLogout(props: any) {
  try {
    const user_data = { token: null };
    yield put(doneLoginData(user_data));
  } catch (e) {
    yield put(loadLoginFail(e));
  }
}

//

function* fetchUserDataByToken(props: any): any {
  // rename props to action
  const query = props.payload;
  try {
    const user_data: any = yield call(getUserData, query);
    let allow = checkRoleAllow(user_data.data.role)

    yield put(doneLoginData({user_data,allow}));
  } catch (e) {
    yield put(sessionExpired(e)); //make changes for cookies expiry validation
  }
}

const getUserData = async (user: any) => {
  const data = await checkTokenExpire({ token: user });
  return data;
};

//

export function* waitForFetchLoginData() {
  yield takeEvery('GET_LOGIN_DATA', fetchLoginData);
}
export function* waitForFetchLoginLogoutData() {
  yield takeEvery('GET_LOGOUT_DATA', fetchLogout);
}

export function* waitForFetchUserDetailsData() {
  yield takeEvery('GET_USER_TOKEN_DATA', fetchUserDataByToken);
}
