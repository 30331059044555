//For Login
export const getLognData=(data:any)=>{
    return{
        type:'GET_LOGIN_DATA',
        payload:data
    }
}

export const doneLoginData=(data:any)=>{
    return{
        type:'GET_LOGIN_DATA_DONE',
        payload:data,
    }
}
export const loadLoginFail=(e:any)=>{
    return{
        type:'GET_LOGIN_FAILURE',
        payload:e
    }
}
export const sessionExpired=(e:any)=>{
    return{
        type:'GET_SESSION_EXPIRED',
        payload:e
    }
}

//for logout

export const getLogoutData=(data:any)=>{
    return{
        type:'GET_LOGOUT_DATA',
        payload:data
    }
}

//verify user by token

export const getUserData=(data:any)=>{
    return{
        type:'GET_USER_TOKEN_DATA',
        payload:data
    }
} 



