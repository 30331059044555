const authLoginReducer = (state = {}, action: any) => {  
  switch (action.type) {
    case 'GET_LOGIN_DATA':
      state = {
        ...state,
        loading: true,
        data: action.payload,
      };
      return state;
      break;
    case 'GET_LOGIN_DATA_DONE':
      state = {
        ...state,
        loading: false,
        data: action.payload.user_data,
        allowActions:action.payload.allow
      };
      return state;
    case 'GET_LOGIN_FAILURE':
      if(action.payload.status === false){
        alert("Invalid Credentials")
      }
      state = {
        ...state,
        loading: false,
        errors: action.payload,
      };
      return state;
    //SESSION EXPIRY
    case 'GET_SESSION_EXPIRED':
      if(action.payload.status === false){
        alert("Session Expired! Please Login again.")
        localStorage.clear()
      }
      state = {
        ...state,
        loading: false,
        errors: action.payload,
      };
      return state;

    case 'GET_LOGOUT_DATA':
      state = {
        ...state,
        loading: false,
        errors: action.payload,
      };
      return state;
    case 'GET_USER_TOKEN_DATA':
      state = {
        ...state,
        loading: false,
        errors: action.payload,
      };
      return state;

    default:
      return state;
  }
};
export default authLoginReducer;
