import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { postOnlineAdmissionTranslationAuth } from 'src/online translation/Services/online-admission.service';
import { useDispatch, useSelector } from 'react-redux';
import { getLognData } from '../../Redux/action';
import './style.css';

const LoginForm = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();

  const state = useSelector((state: any) => state);

  useEffect(() => {
    if (state.userDetails.data) {
      if (state.userDetails.data.token) {
        localStorage.setItem('token', state.userDetails.data.token);
        navigate('/');
      }

    }
  }, [state]);

  //Login
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!email || !password) {
      alert('please eneter credintials!');
      return false;
    }
    const token = localStorage.getItem('token');
    const data = { email, password, token };
     dispatch(getLognData(data));
    // window.location.reload()
  };
  return (
    <>
      <div className="container" id="container">
        <div className="form-container sign-up-container"></div>
        <div className="form-container sign-in-container">
          <form className="form_class" action="#">
            <h1 className="h1_class">Sign in</h1>

            <input
              className="input_class"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
            />
            <input
              className="input_class"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
            />
            <button className="button_class" onClick={(e) => handleSubmit(e)} style={{cursor:"pointer"}}>
              Sign In
            </button>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1 className="h1_class">Welcome Back!</h1>
              <p className="p_class">
                To keep connected with us please login with your personal info
              </p>
              <button className="ghost button_class" id="signIn">
                Sign In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <h1 className="h1_class">Wysax Online Admission Translation</h1>
              <p className="p_class">Welcome To, Wysax Online Admission Translation</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
