import axios from "./baseService/axiosInstance.service";
const URL = process.env.REACT_APP_API_URL;

export const getOnlineAdmissionUiTranslation = (role:string) => {
  return axios.get(`${URL}/online-admission-ui-translation/${role}`);
};

export const postOnlineAdmissionUiTranslation = (data: any) => {
  return axios.post(`${URL}/online-admission-ui-translation`, data);
};

export const putOnlineAdmissionUiTranslation = (data: any, id: any) => {
  return axios.put(`${URL}/online-admission-ui-translation/${id}`, data);
};

//download
export const getOnlineAdmissionUiTranslationDownload = () => {
  const langAry = [
    { lang: "english", key: "en" }, { lang: "arabic", key: "ar" }, { lang: "spanish ", key: "es" }, { lang: "french ", key: "fr" },
  ]
  for (let i = 0; i < langAry.length; i++) {
    return axios.get(`${URL}/i18n/download/${langAry[i].key}`, { responseType: 'blob' })
  }
}

//Auth 
export const postOnlineAdmissionTranslationAuth = (data: any) => {
  return axios.post(`${URL}/auth/login`, data,
    {
      withCredentials: true,
    });
}

//LOGOUT 
export const postOnlineAdmissionTranslationLogout = () => {
  return axios.post(`${URL}/auth/logout`,
    {
      withCredentials: true,
    });
}

// this api used for delete record
export const postDeleteAdmissionUiTranslation = (data: any) => {
  return axios.post(`${URL}/delete-online-admission-field-translation`, data);
};



// 
/* School Apis call */

export const getOnlineSchoolUiTranslation = (role:string) => {
  return axios.get(`${URL}/school/school-ui-translation/${role}`);
};


export const postOnlineSchoolUiTranslation = (data: any) => {
  return axios.post(`${URL}/school/school-ui-translation`, data);
};


export const putOnlineSchoolUiTranslation = (data: any, id: any) => {
  return axios.put(`${URL}/school/school-ui-translation/${id}`, data);
};


export const postDeleteSchoolUiTranslation = (data: any) => {
  return axios.post(`${URL}/school/delete-online-school-field-translation`, data);
};


export const getOnlineSchoolUiTranslationDownload = (data:any) => {
    return axios.get(`${URL}/school/i18n/download/${data}`, { responseType: 'blob' })
}

export const checkTokenExpire=(data:any)=>{
  return axios.post(`${URL}/auth/verify`,data)
}

// Account

export const getAccountUiTranslation = (role:string) => {
  return axios.get(`${URL}/account/account-ui-translation/${role}`);
};


export const postOnlineAccountTranslation = (data: any) => {
  return axios.post(`${URL}/account/account-ui-translation`, data);
};

export const putAccountUiTranslation = (data: any, id: any) => {
  return axios.put(`${URL}/account/account-ui-translation/${id}`, data);
};


export const postDeleteAccountUiTranslation = (data: any) => {
  return axios.post(`${URL}/account/delete-online-account-field-translation`, data);
};


export const getOnlineAccountUiTranslationDownload = (data:any) => {
  return axios.get(`${URL}/account/i18n/download/{lang}${data}`, { responseType: 'blob' })
}


//hrms

export const getHrmsUiTranslation = (role:string) => {
  return axios.get(`${URL}/hrms/hrms-ui-translation/${role}`);
};

export const postOnlineHrmsTranslation = (data: any) => {
  return axios.post(`${URL}/hrms/hrms-ui-translation`, data);
};

export const putOnlineHrmsUiTranslation = (data: any, id: any) => {
  return axios.put(`${URL}/hrms/hrms-ui-translation/${id}`, data);
};

export const postDeleteHrmsUiTranslation = (data: any) => {
  return axios.post(`${URL}/hrms/delete-online-hrms-field-translation`, data);
};

export const getOnlineHrmsUiTranslationDownload = (data:any) => {
  return axios.get(`${URL}/hrms/i18n/download/${data}`, { responseType: 'blob' })
}

//Timetable

export const getTimetableUiTranslation = (role:string) => {
  return axios.get(`${URL}/timetable/timetable-ui-translation/${role}`);
};

export const postOnlineTimetableTranslation = (data: any) => {
  return axios.post(`${URL}/timetable/timetable-ui-translation`, data);
};

export const putOnlineTimetableUiTranslation = (data: any, id: any) => {
  return axios.put(`${URL}/timetable/timetable-ui-translation/${id}`, data);
};

export const postDeleteTimetableUiTranslation = (data: any) => {
  return axios.post(`${URL}/timetable/delete-online-timetable-field-translation`, data);
};

export const getOnlineTimetableUiTranslationDownload = (data:any) => {
  return axios.get(`${URL}/timetable/i18n/download/${data}`, { responseType: 'blob' })
}
//Transportation

export const getTransportationUiTranslation = (role:string) => { //d
  return axios.get(`${URL}/transportation/transportation-ui-translation/${role}`);
};

export const postOnlineTransportationTranslation = (data: any) => {
  return axios.post(`${URL}/transportation/transportation-ui-translation`, data);
};

export const putOnlineTransportationUiTranslation = (data: any, id: any) => {
  return axios.put(`${URL}/transportation/transportation-ui-translation/${id}`, data);
};

export const postDeleteTransportationUiTranslation = (data: any) => {
  return axios.post(`${URL}/transportation/delete-online-transportation-field-translation`, data);
};

export const getOnlineTransportationUiTranslationDownload = (data:any) => {
  return axios.get(`${URL}/transportation/i18n/download/${data}`, { responseType: 'blob' })
}

//Wysax-Website

export const getWysaxUiTranslation = (role:string) => {
  return axios.get(`${URL}/wysax/wysax-ui-translation/${role}`);
};

export const postOnlineWysaxTranslation = (data: any) => {
  return axios.post(`${URL}/wysax/wysax-ui-translation`, data);
};

export const putOnlineWysaxUiTranslation = (data: any, id: any) => {
  return axios.put(`${URL}/wysax/wysax-ui-translation/${id}`, data);
};

export const postDeleteWysaxUiTranslation = (data: any) => {
  return axios.post(`${URL}/wysax/delete-online-wysax-field-translation`, data);
};

export const getOnlineWysaxUiTranslationDownload = (data:any) => {
  return axios.get(`${URL}/wysax/i18n/download/${data}`, { responseType: 'blob' })
}