import axios from 'axios';
const token = localStorage.getItem("token")
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  withCredentials:true,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
