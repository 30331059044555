import {createStore,combineReducers,applyMiddleware} from 'redux'
import authLoginReducer from './Reducers/login.reducer'




// dev Saga
import createSagaMiddelware from 'redux-saga'
import rootSaga from './Saga/rootSaga'

const sagaMiddelware = createSagaMiddelware()



const commonData:any={
    userDetails:{
        loading:true,
        data:{ 
            token:null
        },
        allowActions:{
            delete:false,
            edit:false,
            download:false,
            check_translate:false
        }
    }
}

// 
const rootReducer = combineReducers({  
    userDetails:authLoginReducer,
})


const store= createStore(rootReducer,commonData,applyMiddleware(sagaMiddelware))
sagaMiddelware.run(rootSaga)


export default store