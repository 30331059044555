import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// import Login from 'src/components/authentication/Login';
import Login from '../pages/login';
import { useSelector } from 'react-redux';
import OnlineTimetable from 'src/online translation/pages/OnlineTimetable';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const state: any = useSelector((state) => state);

  return useRoutes([
    { path: '/login', element: <Login /> },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          element: (
            <Navigate
              to={
                state.userDetails.allowActions.edit
                  ? '/dashboard/admission/translation'
                  : '/dashboard/admission/translation_list'
              }
              replace
            />
          ),
          index: true,
        },
        {
          path: '/dashboard',
          element: <Navigate to="/dashboard/admission/translation" replace />,
          index: true,
        },
        { path: '/dashboard/admission/translation', element: <PageOne /> },
        { path: '/dashboard/admission/translation_list', element: <PageTwo /> },
        { path: '/dashboard/account/translation', element: <Accounting /> },
        {
          path: '/dashboard/school',
          children: [
            { path: '/dashboard/school/translation', element: <SchoolUiTranslation /> },
            { path: '/dashboard/school/translation_list', element: <SchoolUiTranslationList /> },
          ],
        },
        {
          path: '/dashboard/account',
          children: [
            { path: '/dashboard/account/translation', element: <Accounting /> },
            { path: '/dashboard/account/translation_list', element: <AccountList /> },
          ],
        },
        {
          path: '/dashboard/hrms',
          children: [
            { path: '/dashboard/hrms/translation', element: <Hrms /> },
            { path: '/dashboard/hrms/translation_list', element: <HrmsList /> },
          ],
        },

        {
          path: '/dashboard/timetable',
          children: [
            { path: '/dashboard/timetable/translation', element: <Timetable /> },
            { path: '/dashboard/timetable/translation_list', element: <TimetableList /> },
          ],
        },
        // Transportation
        {
          path: '/dashboard/transportation',
          children: [
            { path: '/dashboard/transportation/translation', element: <Transportation /> },
            { path: '/dashboard/transportation/translation_list', element: <TransportationList /> },
          ],
        },

        {
          path: '/dashboard/wysax',
          children: [
            { path: '/dashboard/wysax/translation', element: <Wysax /> },
            { path: '/dashboard/wysax/translation_list', element: <WysaxTranslationList /> },
          ],
        },

        {
          path: '/dashboard/user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: '/dashboard/user/four', element: <SchoolUiTranslationList /> },
            { path: '/dashboard/user/five', element: <PageFive /> },
            { path: '/dashboard/user/six', element: <PageSix /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageOne = Loadable(lazy(() => import('../pages/AdmissionUiTranslation')));
const PageTwo = Loadable(lazy(() => import('../pages/AdmissionUiTranslationList')));
const SchoolUiTranslation = Loadable(lazy(() => import('../pages/SchoolUiTranslation')));
const SchoolUiTranslationList = Loadable(lazy(() => import('../pages/SchoolUiTranslationList')));
const Accounting = Loadable(lazy(() => import('../pages/Accounting')));
const AccountList = Loadable(lazy(() => import('../pages/AccountTranslationList')));
const Hrms = Loadable(lazy(() => import('../pages/Hrms')));
const HrmsList = Loadable(lazy(() => import('../pages/HrmsList')));
const Timetable = Loadable(lazy(() => import('../pages/TimetableUiTranslations')));
const Transportation = Loadable(lazy(() => import('../pages/TransportationUiTranslations')));
const TimetableList = Loadable(lazy(() => import('../pages/TimetableUiTranslationsList')));
const TransportationList = Loadable(lazy(() => import('../pages/TransportationTranslationsList')));
const Wysax = Loadable(lazy(() => import('../pages/WysaxUiTranslations')));
const WysaxTranslationList = Loadable(lazy(() => import('../pages/WysaxUiTranslationsList')));
const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
